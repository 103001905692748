//This files contain styles shared across themes
import { ImageLoader, ImageLoaderProps } from 'next/dist/client/image';
import { ReactChildren, ReactElement } from 'react';
import styled, { css } from 'styled-components';

//Breakpoints
export const breakpoints = {
  sm: '360',
  smHero: '480',
  smLogo: '650',
  md: '800',
  mdLogo: '1200',
  lg: '1250',
  lgLogo: '1440',
  navxl: '1580',
  pillarxl: '1600',
  xl: '1920',
};

export const imageLoaderSnapWidth = (width: number, height: number): {width: number, height: number} => {
  const allowedWidths = [16, 32, 48, 64, 96, 128, 256, 384, 592, 768, 1024, 1280, 1600, 1920];
  const maxWidth = Math.max(...allowedWidths);
  let newWidth: number = 0,
     newHeight: number = 0;
  allowedWidths.forEach(w => {
    if (!newWidth && (w >= width || w === maxWidth)) {
      newWidth = w;
      newHeight = (w === width) ? height : Math.ceil((w * height) / width);
    }
  });

  return {
    width: newWidth ? newWidth : width,
    height: newHeight ? newHeight : height,
  };
};
export const imageLoaderResponsive = (width: number, height: number, limit?: boolean): ImageLoader => {
  return (props: ImageLoaderProps): string => {
    const devicePixelRatio = (typeof window === "undefined" ? 1 : window?.devicePixelRatio || 1);
    // Allow max width to be up to a multiple of the requested width, for retina / higher DPI displays.
    const maxWidth = width * devicePixelRatio;
    const w = limit ? Math.min(maxWidth, props.width) : props.width;
    const h = Math.ceil((height * w) / width);
    const snap = imageLoaderSnapWidth(w, h);
    return props.src
      .replace('_WIDTH_', snap.width.toString())
      .replace('_HEIGHT_', snap.height.toString());
  };
};
export const imageLoaderFill = (width: number, height: number): ImageLoader => {
  return (props: ImageLoaderProps): string => {
    const snap = imageLoaderSnapWidth(props.width, height);
    return props.src
      .replace('_WIDTH_', snap.width.toString())
      .replace('_HEIGHT_', snap.height.toString());
  };
};

//Media queries
export const mediaQueries = {
  sm: `@media screen and (min-width: ${breakpoints.sm}px)`,
  smHero: `@media screen and (min-width: ${breakpoints.smHero}px)`,
  md: `@media screen and (min-width: ${breakpoints.md}px)`,
  mdLogo: `@media screen and (min-width: ${breakpoints.mdLogo}px)`,
  lg: `@media screen and (min-width: ${breakpoints.lg}px)`,
  lgLogo: `@media screen and (min-width: ${breakpoints.lgLogo}px)`,
  navxl: `@media screen and (min-width: ${breakpoints.navxl}px)`,
  pillarxl: `@media screen and (min-width: ${breakpoints.pillarxl}px)`,
  xl: `@media screen and (min-width: ${breakpoints.xl}px)`,
};

//Container
export const container = css`
  max-width: 1528px;
  margin: 0 auto;
  padding: 0 1rem;

  ${mediaQueries.md} {
    padding: 0 2rem;
  }
`;

//Grid
export const grid = css`
  display: grid;
  grid-template-columns: repeat(5, [col-start] 1fr);
  gap: 1rem;

  ${mediaQueries.lg} {
    grid-template-columns: repeat(10, [col-start] 1fr);
    gap: 2rem;
  }
`;

export const GridContainer = styled.div`
  ${container}
  ${grid}
  margin-bottom: 3rem;

  &.RichTextParagraph + .RichTextParagraph {
    margin-top: -2rem;
  }
  &.ImagesParagraph + .ImagesParagraph {
    margin-top: -2rem;
  }

  ${mediaQueries.lg} {
    grid-template-columns: repeat(10, [col-start] 1fr);
    gap: 2rem;
    margin-bottom: 4rem;
  }

  &.LastModified {
    margin-bottom: 2rem;
  }
`;

export type GridWrapperProps = {
  alignment: string;
};
export const GridWrapper = styled.div<GridWrapperProps>`
  // Grid column alignment
  grid-column: 1 / span 5;

  ${mediaQueries.md} {
    grid-column: ${(props) => {
      switch (props.alignment) {
        case 'listing':
          return '1 / span 4';
        case 'left':
          return '2 / span 2';
        case 'center':
        case 'centre':
          return '2 / span 3';
        case 'right':
          return '3 / span 2';
      }
    }};
  }
  ${mediaQueries.lg} {
    grid-column: ${(props) => {
      switch (props.alignment) {
        case 'left':
          return '3 / span 4';
        case 'membership':
          return '2 / span 6';
        case 'membership-table':
          return '2 / span 4';
        case 'center':
        case 'centre':
          return '3 / span 6';
        case 'right':
          return '5 / span 4';
        case 'listing':
        case 'centre-wide':
        case 'center-wide':
          return '2 / span 8';
        default:
          return '1 / span 10';
      }
    }};
  }
`;
export const GridContainerFullBleed = styled(GridContainer)`
  padding: 0;
`;

export const DynamicGrid = (cols = 5) => css`
  display: grid;
  grid-template-columns: repeat(1, [col-start] 1fr);
  gap: 1rem;

  ${mediaQueries.md} {
    grid-template-columns: repeat(${cols}, [col-start] 1fr);
    gap: 1.5rem;
  }
`;

export const Container = styled.div`
  ${container}
  padding: 1rem;
  ${mediaQueries.md} {
    padding: 2rem;
  }
`;

//Colors
export const colors = {
  parakeet: '#C7F700',
  gecko: '#7AC143',
  seagrassDark: '#284B0D',
  seagrass: '#008945', // Brand colour is: #008C46 - tweaked here for improved contrast.
  salmon: '#FF8DD3',
  flamingo: '#EC008C',
  butterfly: '#800A6A',
  panther: '#002010',
  sky: '#00DEFF',
  ocean: '#004EFF',
  peacock: '#0000AA',
  honeyBee: '#FFD200',
  tiger: '#ED7A23',
  redPanda: '#BD0C00',
  zooBg: '#F9F0ED',
  londonBg: '#FBFFF6',
  whipsnadeBg: '#F9F0ED',
  white: '#FFFFFF',
  white60: '#DDDDDD',
  black: '#000000',
  black60: '#444444',
  zooFont: '#330906',
  silver: '#F0F0F0',
};

//Typography

//Only h1 has different styles between zsl and the zoos
export const h1 = css`
  font-family: ${(props) => props.theme.fonts.h1};

  ${(props) =>
    props.theme.key === 'zsl'
      ? `font-size: 2.5rem;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 105%;

  ${mediaQueries.lg} {
    font-size: 5rem;
  }`
      : `font-size: 2.25rem;
  font-weight: 600;
  line-height: 110%;

  ${mediaQueries.lg} {
    font-size: 5rem;
  }
`}
`;

export const h2 = css`
  font-family: ${(props) => props.theme.fonts.heading};
  font-size: 2rem;
  font-weight: 600;
  line-height: 110%;

  ${mediaQueries.lg} {
    font-size: 3rem;
  }
`;

export const hFeature = css`
  font-family: ${(props) => props.theme.fonts.feature};
  font-weight: 800;
  text-transform: uppercase;
`;

export const h3 = css`
  font-family: ${(props) => props.theme.fonts.heading};
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 110%;

  ${mediaQueries.lg} {
    font-size: 2.25rem;
  }
`;

export const h4 = css`
  font-family: ${(props) => props.theme.fonts.heading};
  font-size: 1.25rem;
  font-weight: ${(props) => props.theme.key === 'zsl' ? `600` : `700`};
  line-height: 120%;

  ${mediaQueries.lg} {
    font-size: 1.5rem;
  }
`;

export const h5 = css`
  font-family: ${(props) => props.theme.fonts.heading};
  font-size: 1rem;
  font-weight: ${(props) => props.theme.key === 'zsl' ? `600` : `700`};
  line-height: 120%;

  ${mediaQueries.lg} {
    font-size: 1.25rem;
  }
`;

export const eyebrow = css`
  color: ${(props) => props.theme.colors.eyebrow};
  font-family: ${(props) => props.theme.fonts.eyebrow};
  font-size: 1rem;
  font-weight: 700;
  line-height: 120%;
  text-transform: uppercase;

  ${mediaQueries.lg} {
    font-size: 1.125rem;
  }
`;

export const paragraph = css`
  font-size: 1rem;
  font-weight: 400;
  line-height: 140%;

  ${mediaQueries.lg} {
    font-size: 1.125rem;
    line-height: 145%;
  }
`;

export const caption = css`
  font-size: 0.75rem;
  line-height: 120%;

  ${mediaQueries.lg} {
    font-size: 0.875rem;
  }
`;

export const buttonFont = css`
  font-family: ${(props) => props.theme.fonts.button};
  font-size: 1rem;
  font-weight: 700;
  line-height: 120%;

  ${mediaQueries.lg} {
    font-size: 1.125rem;
  }
`;

//Button style
export const buttonTemplate = css`
  ${buttonFont}
  border-radius: ${props => props.theme.config.buttonRadius};
  padding: 0.875rem 1rem 0.75rem;
  cursor: pointer;
  text-transform: uppercase;
  border: 2px solid transparent;
  display: inline-block;
  transition: border-color 0.1s;
  text-align: center;

  white-space: normal;
  max-width: min(90vw, 480px);
  min-width: 60px;

  ${mediaQueries.md} {
    overflow: initial;
    text-overflow: initial;
    max-width: initial;
    min-width: initial;
  }

  ${mediaQueries.lg} {
    padding: 1.125rem 1.75rem 1rem;
  }

  &:hover,
  &:focus {
    text-decoration: none;
  }

  &::after {
    display: none;
  }
`;

export const buttonPrimary = css`
  ${buttonTemplate}
  color: ${(props) => props.theme.colors.button.primary.text};
  background-color: ${(props) => props.theme.colors.button.primary.bg};
  border-color: ${(props) => props.theme.colors.button.primary.border};
  &:hover,
  &:focus {
    text-decoration: none;
    border-color: ${(props) => props.theme.colors.button.primary.focusBorder};
  }
`;

export const buttonSecondary = css`
  ${buttonTemplate}
  color: ${(props) => props.theme.colors.button.secondary.text};
  background-color: ${(props) => props.theme.colors.button.secondary.bg};
  border-color: ${(props) => props.theme.colors.button.secondary.border};
  &:hover,
  &:focus {
    text-decoration: none;
    border-color: ${(props) => props.theme.colors.button.secondary.focusBorder};
  }
`;

export const buttonPrimaryBlack = css`
  ${buttonTemplate}
  color: ${colors.black};
  background-color: ${colors.white};
  border-color: ${colors.black};
  &:hover,
  &:focus {
    text-decoration: none;
    background-color:  ${colors.white60};
    color: ${colors.black60};
  }
`;

export const buttonSecondaryBlack = css`
  ${buttonTemplate}
  color: ${colors.white};
  background-color: ${colors.black};
  border-color: ${colors.white};
  &:hover,
  &:focus {
    text-decoration: none;
    background-color: ${colors.black60};
    color:  ${colors.white60};
  }
`;

export const buttonNews = css`
  ${buttonTemplate}
  color: ${(props) => props.theme.colors.button.news.text};
  background-color: ${(props) => props.theme.colors.button.news.bg};
  border-color: ${(props) => props.theme.colors.button.news.border};
  &:hover,
  &:focus {
    text-decoration: none;
    border-color: ${(props) => props.theme.colors.button.news.focusBorder};
  }
`;

export const buttonPill = css`
  ${buttonTemplate}
  color: ${(props) => props.theme.colors.button.pill.text};
  background-color: ${(props) => props.theme.colors.button.pill.bg};
  border-color: ${(props) => props.theme.colors.button.pill.border};
  &:hover,
  &:focus {
    text-decoration: none;
    border-color: ${(props) => props.theme.colors.button.pill.focusBorder};
  }

  svg {
    margin-left: 0.625rem;
    fill: ${(props) => props.theme.colors.button.pill.text}
  }
`;

// Input style.
export const inputTemplate = css`
  font-size: 1rem;
  font-family: ${(props) => props.theme.fonts.body};
  font-weight: 400;
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 0.875rem 1rem 0.75rem;
  white-space: nowrap;
  border: 2px solid transparent;
  transition: border-color 0.1s;
  text-overflow: ellipsis;
  ${mediaQueries.lg} {
    padding: 1.125rem 1.75rem 1rem;
  }
`;

// Link style.
export const linkTemplate = css`
  text-decoration: none;
  ${props => props.theme.key !== 'zsl' && `font-weight: 600;`}

  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
  }

  &[target='_blank']::after {
    content: '';
    display: inline-block;
    width: 0.875rem;
    height: 0.875rem;
    background-image: url(${(props) => `/external-${props.theme.key}.svg`});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    bottom: -0.175rem;
    margin-left: 0.25rem;
  }
`;
export const linkTemplateUnderline = css`
  ${linkTemplate}
  text-decoration: underline;

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }
`;

// Text.
export const Text = styled.div`
  ${paragraph}
  color: ${(props) => props.theme.colors.text};
  margin: 1rem;
  ${mediaQueries.lg} {
    margin-top: 2rem;
  }
`;

export const SmallText = styled.div`
  ${caption}
  color: ${(props) => props.theme.colors.text};
`;

export const Date = styled.div`
  ${caption}
  display: inline;
  padding-bottom: 0.75rem;
  border-bottom: 2px solid ${(props) => props.theme.colors.dateUnderlineColor};
  color: ${(props) => props.theme.colors.text};
`;

// Media image.
export type MediaImage = {
  url: string;
  sm: string;
  md: string;
  lg: string;
  xl: string;
};

export const BorderRadius = (radius: string, smoothCorners?: string) => css`
  ${(props) => props.theme.key === 'zsl' ?
  `border-radius: ${radius};`
  :
  `border-radius: ${radius};
   @supports (mask-image: paint(smooth-corners)) {
      border-radius: 0;
      --smooth-corners: ${smoothCorners ? smoothCorners : 12};
      mask-image: paint(smooth-corners);
   }`};
`;

export type CondtionalWrapperProps = {
  condition: boolean;
  wrapper: any;
  children: ReactElement | ReactChildren | string;
};
export const ConditionalWrapper = ({ condition, wrapper, children }: CondtionalWrapperProps) =>
  condition ? wrapper(children) : children;

export const imageBackgroundTemplate = (image: string) => css`
  background-image: url(${JSON.parse(image).url});

  ${mediaQueries.sm} {
    background-image: url(${JSON.parse(image).sm});
  }

  ${mediaQueries.md} {
    background-image: url(${JSON.parse(image).lg});
  }

  ${mediaQueries.lg} {
    background-image: url(${JSON.parse(image).lg});
  }

  ${mediaQueries.xl} {
    background-image: url(${JSON.parse(image).xl});
  }
`;

export const textTitle = css`
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.5rem;

  ${mediaQueries.lg} {
    font-size: 1.5rem;
    line-height: 1.8rem;
  }
`;
