import { themes } from "@/theme";

export const getFrontendHostname = () => {
  if (process.env.FRONTEND_HOSTNAME) {
    return process.env.FRONTEND_HOSTNAME;
  }
  if (typeof window !== "undefined") {
    return `${window?.location?.hostname}` || '';
  }
  return '';
}

export const getCmsHostname = () => {
  if (process.env.CMS_HOSTNAME) {
    return process.env.CMS_HOSTNAME;
  }
  const frontend = getFrontendHostname();
  return frontend.replace('www.', 'cms.');
}

export const getGraphqlUri = (): string => {
  // Lookup the CMS domain from the frontend domain. Fall back to platform.sh internal URL if there's no match (as
  // it can only be accessed from within Platform.sh)
  return process.env.GRAPHQL_URI || 'http://backend.internal/graphql';
};

export const getTheme = () : keyof typeof themes => {
  // Workaround for local dev. Environment variables are baked-in at compile time.
  if (process.env.NODE_ENV === 'development') {
    // FRONTEND_THEME is compiled-in so it can be used on the client-side. We need a dynamic value for Lando so it can
    // serve all 3 sites from one codebase.
    if (typeof window !== 'undefined' && window?.location) {
      switch (window?.location?.href) {
        case 'zsl.lndo.site':
        case 'www.zsl.lndo.site':
          return 'zsl';
        case 'london.lndo.site':
        case 'www.london.lndo.site':
          return 'london';
        case 'whipsnade.lndo.site':
        case 'www.whipsnade.lndo.site':
          return 'whipsnade';
      }
    }
    return process.env.LNDO_SITE as keyof typeof themes;
  }
  return process.env.FRONTEND_THEME as keyof typeof themes;
}

export const getSiteName = (): string => {
  const theme = getTheme();

  switch (theme) {
    default:
    case 'zsl':
      return 'The Zoological Society of London';
    case 'london':
      return 'London Zoo';
    case 'whipsnade':
      return 'Whipsnade Zoo';
  }
}

export const formatDefaultMetaTitle = (title: string) : string => {
  return `${title} | ${getSiteName()}`;
}

export const getCookieProDataDomain = (theme: keyof typeof themes) : string => {
  switch (theme) {
    default:
    case 'zsl':
      if (!process.env.FRONTEND_HOSTNAME?.includes('zsl.org')) {
        return '018f34c9-1bfd-70b6-b917-6b9e512949c6';
      }
      return '4172d1da-7241-4c2b-bb3b-819a0cd81190';
    case 'london':
      return '23954f19-2e0f-4c71-b643-c94edf5a0e79';
    case 'whipsnade':
      return '4fb6a197-68a6-425d-a730-2ddf5d188748';
  }
};

export const getSiteSource = (): string => {
  const theme = getTheme();
  switch (theme) {
    case 'london':
      return 'LZ';
    case 'whipsnade':
      return 'WZ';
    default:
      return '';
  }
};

export const getLanguage = (): string => {
  let lang = "en";
  if (typeof window !== "undefined") {
    const langURL = window?.location?.pathname.split('/')[1];
    if (['fr', 'de', 'es'].includes(langURL)) {
      lang =  langURL;
    }
  }
  return lang;
};